import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, Text, VStack, HStack, Image, Modal, ModalOverlay, ModalContent, 
  ModalBody, IconButton, Link, Accordion, AccordionItem, AccordionButton, 
  AccordionPanel, SimpleGrid, Spinner, Center, Circle, Grid,
  Flex,
  Icon
} from '@chakra-ui/react';
import { HiHome } from 'react-icons/hi'; // Add this import
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Header from './Header';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { motion, AnimatePresence } from 'framer-motion';
import remarkGfm from 'remark-gfm';
// Types
interface ItemData {
    snippetId: string;
    title: string;
    content: string;
    searchQuery: string;
    topicId: string;
    topicTitle: string;
    snippetDetail: any;
    imageResults: Array<{
      url: string;
      position: number;
    }>;
  }

  const FeaturedImageContainer = ({ image, onClick, totalImages }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
  
    // Add custom shimmer styles
    const shimmerStyles = `
    .shimmer-container {
      position: relative;
      overflow: hidden;
      background: #1a1a1a;
    }

    .shimmer-effect {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: shimmer 2s infinite linear;
    }

    @keyframes shimmer {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  `;
  
    return (
      <Box 
        position="relative" 
        w="full" 
        h="250px"
        mb={8}
        overflow="hidden"
        onClick={onClick}
        cursor="pointer"
      >
        <style>{shimmerStyles}</style>
        
        {/* Shimmer Loading State */}
        {isLoading && (
          <Box 
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            className="shimmer-container"
            zIndex={1}
          >
            <Box className="shimmer-effect" />
          </Box>
        )}
  
        {/* Actual Image */}
        {image && (
          <Box position="relative" h="full" opacity={isLoading ? 0 : 1}>
            <Box
              position="relative"
              w="full"
              h="full"
            >
              <Image
                src={image.url}
                alt="Featured image"
                objectFit="cover"
                w="full"
                h="full"
                onLoad={() => setIsLoading(false)}
                onError={() => {
                  setIsLoading(false);
                  setHasError(true);
                }}
                style={{ display: hasError ? 'none' : 'block' }}
              />
              {hasError && <ImageFallback source={image.source} />}
            </Box>
  
            {/* Tags Container */}
            <Box
              position="absolute"
              bottom={4}
              left={0}
              right={0}
              px={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              zIndex={2}
            >
              {/* Source/Citation Tag */}
              <Box
                bg="blackAlpha.700"
                color="white"
                px={3}
                py={1}
                borderRadius="full"
                fontSize="sm"
              >
                <Text noOfLines={1} maxW="17ch">
                  {image.source}
                </Text>
              </Box>
  
              {/* Gallery Count - Only show if there are more images */}
              {totalImages > 1 && (
                <Box
                  bg="blackAlpha.700"
                  color="white"
                  px={3}
                  py={1}
                  borderRadius="full"
                  fontSize="sm"
                  whiteSpace="nowrap"
                >
                  +{totalImages - 1} images
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const CitationLink = ({ index, url }) => {
    let finalUrl = '#';
    if (url) {
      finalUrl = url.startsWith('http') ? url : `https://${url}`;
    }
  
    return (
      <Link
        href={finalUrl}
        isExternal={!!url && finalUrl !== '#'}
        display="inline-flex"
        alignItems="center"
        textDecoration="none"
        _hover={{ textDecoration: 'none' }}
        cursor={url ? 'pointer' : 'default'}
        // Remove any default margin/padding that might create spacing
        margin="0"
        padding="0"
      >
        <Circle
          size="18px" // Slightly smaller circle to match the smaller font
          bg="#1c1c1c"
          color="gray.500"
          fontSize="xs" // Smaller font size (changed from "sm" to "xs")
          fontWeight="medium"
          _hover={{ bg: url ? 'whiteAlpha.200' : 'whiteAlpha.100' }}
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
        >
          {index}
        </Circle>
      </Link>
    );
};

// Update ColumnDivider to use the same navigation logic
const ColumnDivider = ({ onNext, onPrev }) => (
  <Box
    position="relative"
    width="1px"
    bg="whiteAlpha.200"
    mx={0}
  >
    <VStack
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      spacing={4}
    >
      <IconButton
        icon={<ChevronUpIcon boxSize={5} />}
        aria-label="Previous item"
        onClick={onPrev}
        width="40px"
        height="40px"
        bg="transparent"
        color="white"
        borderRadius="md"
        sx={{
          '&:hover': {
            bg: 'white',
            '& svg': {
              color: 'black',
            },
          },
          '&:active': {
            bg: 'whiteAlpha.800',
          },
        }}
      />
      <IconButton
        icon={<ChevronDownIcon boxSize={5} />}
        aria-label="Next item"
        onClick={onNext}
        width="40px"
        height="40px"
        bg="transparent"
        color="white"
        borderRadius="md"
        sx={{
          '&:hover': {
            bg: 'white',
            '& svg': {
              color: 'black',
            },
          },
          '&:active': {
            bg: 'whiteAlpha.800',
          },
        }}
      />
    </VStack>
  </Box>
);

const ShimmerBox = ({ height = "20px", width = "100%", mb = 0 }) => (
  <Box 
    className="shimmer-container" 
    h={height} 
    w={width}
    mb={mb}
    borderRadius="md"
  >
    <Box className="shimmer-effect" />
  </Box>
);

const LoadingAccordionItem = () => (
  <Box 
    bg="whiteAlpha.50" 
    p={4} 
    mb={3} 
    borderRadius="md"
  >
    <ShimmerBox height="24px" width="90%" />
  </Box>
);

const LoadingCitationCard = () => (
  <Box 
    bg="whiteAlpha.100" 
    p={2}
    borderRadius="md"
    w="full"
  >
    <HStack spacing={3} align="start">
      <Box className="shimmer-container" w="160px" h="90px" borderRadius="md">
        <Box className="shimmer-effect" />
      </Box>
      <VStack spacing={2} align="start" flex={1}>
        <ShimmerBox height="16px" width="90%" />
        <ShimmerBox height="14px" width="40%" />
      </VStack>
    </HStack>
  </Box>
);

const ImageGridShimmer = () => (
  <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
    {[...Array(8)].map((_, i) => (
      <Box
        key={i}
        className="shimmer-container"
        aspectRatio="1"
        borderRadius="md"
      >
        <Box className="shimmer-effect" />
      </Box>
    ))}
  </Box>
);

  // Update loading component to maintain scroll position
  const ItemDetailLoading = ({ isMobile = false }) => {
    // Ensure window is scrolled to top during loading
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const shimmerStyles = `
      .shimmer-container {
        position: relative;
        overflow: hidden;
        background: #1a1a1a;
      }
  
      .shimmer-effect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.1) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: shimmer 2s infinite linear;
      }
  
      @keyframes shimmer {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(100%);
        }
      }
    `;

    if (isMobile) {
      return (
        <Box bg="black" minH="100vh">
          <Header />
          <style>{shimmerStyles}</style>
          
          {/* Featured Image Skeleton */}
          <Box className="shimmer-container" h="300px" w="full">
            <Box className="shimmer-effect" />
          </Box>

          {/* Content Container - Ensure correct order */}
          <VStack spacing={8} p={4} align="stretch">
            {/* Title Skeleton */}
            <ShimmerBox height="32px" width="90%" />
            
            {/* Description Skeleton */}
            <VStack align="stretch" spacing={3}>
              <ShimmerBox height="16px" width="95%" />
              <ShimmerBox height="16px" width="90%" />
              <ShimmerBox height="16px" width="85%" />
            </VStack>

            {/* Search Summary Skeleton */}
            <VStack align="stretch" spacing={3}>
              <ShimmerBox height="16px" width="95%" />
              <ShimmerBox height="16px" width="88%" />
              <ShimmerBox height="16px" width="92%" />
            </VStack>

            {/* Q&A Section */}
            <VStack align="stretch" spacing={3} pt={4}>
              <ShimmerBox height="24px" width="40%" />
              {[...Array(3)].map((_, i) => (
                <LoadingAccordionItem key={i} />
              ))}
            </VStack>
          </VStack>
        </Box>
      );
    }

    return (
      <Box bg="black" minH="100vh">
        <style>{shimmerStyles}</style>
        
        <Box pt="48px" pl="200px">
          <Box display="flex" h="calc(100vh - 48px)">
            {/* Left Column: Main Content */}
            <Box flex="1.5" px={8} borderRight="1px solid" borderColor="whiteAlpha.200">
              <VStack align="stretch" spacing={8} py={8}>
                {/* Featured Image Shimmer */}
                <Box className="shimmer-container" h="400px" w="full" borderRadius="xl">
                  <Box className="shimmer-effect" />
                </Box>
                
                {/* Title Shimmer */}
                <ShimmerBox height="40px" width="80%" />
                
                {/* Content Shimmer */}
                <VStack align="stretch" spacing={3}>
                  <ShimmerBox height="16px" width="95%" />
                  <ShimmerBox height="16px" width="90%" />
                  <ShimmerBox height="16px" width="85%" />
                  <ShimmerBox height="16px" width="92%" />
                </VStack>
  
                {/* Citations Shimmer */}
                <VStack align="stretch" spacing={3}>
                  <ShimmerBox height="24px" width="40%" />
                  {[...Array(3)].map((_, i) => (
                    <LoadingCitationCard key={i} />
                  ))}
                </VStack>
              </VStack>
            </Box>
  
            {/* Right Column: Image Grid */}
            <Box flex="1" px={8} py={8}>
              <ImageGridShimmer />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

const MobileNavBar = ({ onNext, onPrev, onHome }) => (
  <Box
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        bg="black"
        borderTop="1px solid"
        borderColor="whiteAlpha.200"
        py={2}
        zIndex={100} // Increased z-index
      >
        <HStack justifyContent="center" spacing={0}>
          <IconButton
            icon={<ChevronLeftIcon boxSize={10} />}
            aria-label="Previous item"
            onClick={onPrev}
            color="white"
            bg="transparent"
            borderRadius="md"
            size="lg"
            width="5rem"
            height="3rem"
            _hover={{ bg: "whiteAlpha.100" }}
          />
          <IconButton
            icon={<HiHome size={24} />}
            aria-label="Home"
            onClick={onHome}
            color="white"
            bg="transparent"
            borderRadius="md"
            size="lg"
            width="5rem"
            height="3rem"
            _hover={{ bg: "whiteAlpha.100" }}
          />
          <IconButton
            icon={<ChevronRightIcon boxSize={10} />}
            aria-label="Next item"
            onClick={onPrev}
            color="white"
            bg="transparent"
            borderRadius="md"
            size="lg"
            width="5rem"
            height="3rem"
            _hover={{ bg: "whiteAlpha.100" }}
          />
        </HStack>
      </Box>
);

// ImageFallback component for consistent fallback styling
const ImageFallback = ({ source, size = "full" }) => {
  const letter = source?.charAt(0).toUpperCase() || '?';
  
  return (
    <Box 
      w={size} 
      h={size} 
      bg="whiteAlpha.200"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="md"
    >
      <Text
        fontSize={size === "full" ? "48px" : "24px"}
        fontWeight="medium"
        color="gray.400"
      >
        {letter}
      </Text>
    </Box>
  );
};

// Updated ImageGalleryGrid with fallback
const ImageGalleryGrid = ({ images, onImageClick }) => {
  const limitedImages = images.slice(0, 10);
  
  return (
    <SimpleGrid 
      columns={{ base: 2, sm: 2, md: 3 }} 
      spacing={{ base: 6, md: 4 }}
      w="full" 
      p={{ base: 2, md: 4 }}
    >
      {limitedImages.map((image, index) => (
        <Box
          key={index}
          position="relative"
          cursor="pointer"
          onClick={() => onImageClick(index)}
          borderRadius="md"
          overflow="hidden"
          transition="transform 0.2s"
          _hover={{ transform: 'scale(1.02)' }}
          aspectRatio={{ base: "4/3", md: "16/9" }}
          maxH={{ base: "70vh", md: "unset" }}
        >
          {image.url ? (
            <Image
            src={image.url}
            alt={`Gallery image ${index + 1}`}
            objectFit="cover"
            w="full"
            h="full"
            onError={(e) => {
              console.log('Image load error:', image.url);
              // Optionally set a fallback
              if (e.target instanceof HTMLImageElement) {
                e.target.style.display = 'none';
              }
            }}
          />
          ) : (
            <ImageFallback source={image.source} />
          )}
          <Box
            position="absolute"
            bottom={2}
            left="50%"
            transform="translateX(-50%)"
            display="inline-block"
          >
            <Link
              color="white"
              fontSize={{ base: "sm", md: "xs" }}
              bg="blackAlpha.700"
              px={2}
              py={1}
              borderRadius="md"
              _hover={{ bg: "blackAlpha.800" }}
              textAlign="center"
              whiteSpace="nowrap"
              display="inline-block"
            >
              {image.source || extractDomainName(image.link)}
            </Link>
          </Box>
        </Box>
      ))}
    </SimpleGrid>
  );
};
  
const FullScreenImage = ({
  currentImageIndex,
  setCurrentImageIndex,
  images,
  onClose,
  imageLoading,
  setImageLoading,
}) => {
  const limitedImages = images.slice(0, 10);
  const currentImage = limitedImages[currentImageIndex];
  const [hasError, setHasError] = useState(false);

  // Reset error state when image changes
  useEffect(() => {
    setHasError(false);
    setImageLoading(true);
  }, [currentImageIndex, setImageLoading]);
  
  return (
    <HStack justify="center" align="center" h="100vh">
      <IconButton
        icon={<ChevronLeftIcon boxSize={8} />}
        onClick={() => {
          setCurrentImageIndex(prev => prev - 1);
        }}
        isDisabled={currentImageIndex === 0}
        aria-label="Previous image"
        variant="ghost"
        color="white"
        size="lg"
      />
      <Box position="relative" h="80vh" w="80vw">
        {imageLoading && !hasError && (
          <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" bg="black">
            <Spinner size="xl" color="white" />
          </Center>
        )}
        
        {!hasError && currentImage.url ? (
          <Image
            src={currentImage.url}
            alt={`Image ${currentImageIndex + 1}`}
            objectFit="contain"
            h="full"
            w="full"
            onLoad={() => setImageLoading(false)}
            onError={() => {
              setHasError(true);
              setImageLoading(false);
            }}
            style={{ opacity: imageLoading ? 0 : 1 }}
            transition="opacity 0.3s"
          />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" h="full" w="full">
            <ImageFallback source={currentImage.source} size="full" />
          </Box>
        )}
        
        <Box
          position="absolute"
          bottom={4}
          left="50%"
          transform="translateX(-50%)"
          display="inline-block"
        >
          <Link
            href={currentImage.link}
            color="white"
            fontSize={{ base: "sm", md: "sm" }}
            isExternal
            bg="blackAlpha.700"
            px={3}
            py={1}
            borderRadius="md"
            _hover={{ bg: "blackAlpha.800" }}
            textAlign="center"
            whiteSpace="nowrap"
            display="inline-block"
          >
            {currentImage.source || extractDomainName(currentImage.link)}
          </Link>
        </Box>
      </Box>
      <IconButton
        icon={<ChevronRightIcon boxSize={8} />}
        onClick={() => {
          setCurrentImageIndex(prev => prev + 1);
        }}
        isDisabled={currentImageIndex === limitedImages.length - 1}
        aria-label="Next image"
        variant="ghost"
        color="white"
        size="lg"
      />
    </HStack>
  );
};
  
  const ImageGallery = ({ 
    isOpen, 
    onClose, 
    currentImageIndex, 
    setCurrentImageIndex, 
    images,
  }) => {
    const [imageLoading, setImageLoading] = useState(true);
    const [showFullScreen, setShowFullScreen] = useState(false);
  
    const handleImageClick = (index) => {
      setCurrentImageIndex(index);
      setShowFullScreen(true);
      setImageLoading(true);
    };
  
    return (
      <Modal 
        isOpen={isOpen} 
        onClose={() => {
          setShowFullScreen(false);
          onClose();
        }} 
        size="full"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent bg="rgba(0, 0, 0, 0.95)">
          <ModalBody p={0} position="relative">
            <IconButton
              icon={<CloseIcon />}
              position="absolute"
              right={4}
              top={4}
              onClick={() => {
                setShowFullScreen(false);
                onClose();
              }}
              aria-label="Close gallery"
              variant="ghost"
              color="white"
              zIndex={2}
            />
            
            {showFullScreen ? (
              <FullScreenImage
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                images={images}
                onClose={onClose}
                imageLoading={imageLoading}
                setImageLoading={setImageLoading}
              />
            ) : (
              <Box 
                pt={{ base: 12, md: 16 }}
                pb={{ base: 6, md: 8 }}
                px={{ base: 2, md: 4 }}
                maxW="1200px" 
                mx="auto"
              >
                <ImageGalleryGrid 
                  images={images} 
                  onImageClick={handleImageClick}
                />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const ProcessedText = ({ text, citations }) => {
    if (!text) return null;
  
    const preprocessText = (inputText) => {
      let processedText = inputText;
      
      // Replace special brackets and double brackets
      processedText = processedText.replace(/【/g, '[');
      processedText = processedText.replace(/】/g, ']');
      processedText = processedText.replace(/\[\[/g, '[');
      processedText = processedText.replace(/\]\]/g, ']');
  
      // Standardize citation format - handles various spacing patterns
      const citationPattern = /\[\s*citation:\s*(\d+)\s*\]/g;
      processedText = processedText.replace(citationPattern, '[citation:$1]');
  
      return processedText;
    };
  
    const processCitations = (content) => {
      const parts = content.split(/(\[citation:\d+\])/g);
  
      return parts.map((part, i) => {
        const citationMatch = part.match(/\[citation:(\d+)\]/);
  
        if (citationMatch) {
          const citationNumber = parseInt(citationMatch[1], 10);
          const citation = citations?.find(c => c.id === citationNumber);
          const url = citation?.citation_URL;
  
          return (
            <Box 
              key={i} 
              as="span" 
              display="inline-flex" 
              alignItems="center" 
              // mx={1} to eliminate spacing
            >
              <CitationLink
                key={`${i}-citation`}
                index={citationNumber}
                url={url}
              />
            </Box>
          );
        }
  
        return part;
      });
    };
  
    const components = {
      p: ({ children }) => {
        if (typeof children === 'string') {
          return <p>{processCitations(children)}</p>;
        }
        // Handle nested elements
        return (
          <p>
            {React.Children.map(children, child => {
              if (typeof child === 'string') {
                return processCitations(child);
              }
              return child;
            })}
          </p>
        );
      },
      strong: ({ children }) => {
        if (typeof children === 'string') {
          return <strong>{processCitations(children)}</strong>;
        }
        return <strong>{children}</strong>;
      },
      em: ({ children }) => {
        if (typeof children === 'string') {
          return <em>{processCitations(children)}</em>;
        }
        return <em>{children}</em>;
      },
      // Add other markdown components as needed
    };
  
    return (
      <Box
        textAlign="left"
        className="markdown-content"
        fontSize={{ base: "16px", md: "16px" }} // Reduced from md/lg to 16px
        mb={6}
        color="white"
        lineHeight="1.5"
      >
        <ReactMarkdown 
          components={components}
          className="markdown-content"
        >
          {preprocessText(text)}
        </ReactMarkdown>
      </Box>
    );
  };
  
  
  const SectionTitle = ({ children }) => (
    <Text
      fontSize={{ base: "18px", md: "20px" }}
      fontWeight="bold"
      color="white"
      // mb={4}
      textAlign="left"
      w="full"
    >
      {children}
    </Text>
  );


  const formatTitle = (title) => {
    return title.trim().replace(/\s+/g, ' ');
  };

  const extractDomainName = (urlString) => {
    try {
      const url = new URL(urlString);
      const host = url.hostname;
      const components = host.split('.');
      
      if (components.length > 2) {
        if (host.includes('wikipedia')) return 'Wikipedia';
        if (host.includes('nytimes')) return 'The New York Times';
        if (host.includes('bbc')) return 'BBC';
        if (host.includes('cnn')) return 'CNN';
        
        return components[components.length - 2].charAt(0).toUpperCase() + 
               components[components.length - 2].slice(1);
      } else if (components.length === 2) {
        return components[0].charAt(0).toUpperCase() + components[0].slice(1);
      } else {
        return host.charAt(0).toUpperCase() + host.slice(1);
      }
    } catch {
      return 'Unknown';
    }
  };

  const getSiteName = (citation) => {
    if (citation.citation_source === 'knowledge_graph') {
      return 'Google Knowledge Graph';
    } else if (citation.citation_OG_Site_Name?.trim()) {
      return citation.citation_OG_Site_Name;
    } else {
      return extractDomainName(citation.citation_URL);
    }
  };

  // Updated CitationCard with fallback
// CitationCard with error handling and proper fallback
const CitationCard = ({ citation }) => {
  const siteName = getSiteName(citation);
  const [hasError, setHasError] = useState(false);
  
  return (
    <Link
      href={citation.citation_URL}
      isExternal
      _hover={{ textDecoration: 'none' }}
      w="full"
      display="block"
    >
      <Box 
        bg="whiteAlpha.100" 
        _hover={{ bg: "whiteAlpha.200" }}
        cursor="pointer"
        transition="background 0.2s"
      >
        <HStack spacing={3} p={2} align="start">
          <Box flexShrink={0} w="160px" h="90px" overflow="hidden" borderRadius="md">
            {!hasError && citation.citation_OG_Image_URL ? (
              <Image
                src={citation.citation_OG_Image_URL}
                alt={citation.citation_title}
                objectFit="cover"
                w="full"
                h="full"
                onError={() => setHasError(true)}
              />
            ) : (
              <ImageFallback source={siteName} size="full" />
            )}
          </Box>

          <VStack spacing={1} align="start" flex={1} pl={0}>
            <Text 
              color="white" 
              fontWeight="medium"
              fontSize="14px"
              noOfLines={2}
              textAlign="left"
              w="full"
            >
              {formatTitle(citation.citation_title)}
            </Text>
            
            <Text 
              color="gray.400" 
              fontSize="14px"
              textAlign="left"
              w="full"
            >
              {siteName}
            </Text>
          </VStack>
        </HStack>
      </Box>
    </Link>
  );
};

  // FeaturedImage component with error handling
const FeaturedImage = ({ image, onImageClick }) => {
  const [hasError, setHasError] = useState(false);
  
  if (!image) return null;

  return (
    <Box
      position="relative"
      w="full"
      h="400px"
      borderRadius="xl"
      overflow="hidden"
      onClick={onImageClick}
      cursor="pointer"
      transition="transform 0.2s"
      _hover={{ transform: 'scale(1.01)' }}
    >
      {!hasError && image.url ? (
        <Image
          src={image.url}
          alt="Featured image"
          objectFit="cover"
          w="full"
          h="full"
          onError={() => setHasError(true)}
        />
      ) : (
        <ImageFallback source={image.source} />
      )}
      
      <Box
        position="absolute"
        bottom={4}
        left={4}
        bg="blackAlpha.700"
        color="white"
        px={3}
        py={1}
        borderRadius="full"
        fontSize="sm"
      >
        {image.source}
      </Box>
    </Box>
  );
};

  const ItemDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [allImages, setAllImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [showTransitionIndicator, setShowTransitionIndicator] = useState(null);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const [itemDataMap, setItemDataMap] = useState({}); // Initialize itemDataMap

      // Refs for scroll handling (unchanged)
    const scrollAnimationRef = useRef(null);
    const scrollVelocityRef = useRef(0);
    const scrollDeltaRef = useRef(0);
    const lastScrollTimeRef = useRef(Date.now());

  // Add metadata management
  const updateMetadata = (itemData) => {
    if (!itemData) return;

    // Get the first valid image URL from imageResults
    let imageUrl = '/sharedot_preview_logo.png'; // Your default image
    if (itemData.imageResults && itemData.imageResults.length > 0) {
      const validImage = itemData.imageResults.find(img => {
        if (!img.original) return false;
        try {
          const url = new URL(img.original);
          return !isBlockedDomain(img.original);
        } catch {
          return false;
        }
      });
      
      if (validImage) {
        imageUrl = validImage.original;
      }
    }

    // Generate description from content
    const description = itemData.content ? 
      itemData.content.replace(/\[.*?\]\(.*?\)/g, '').trim() : 
      'The fastest path to information';

    // Update meta tags
    const metaTags = {
      'title': itemData.title,
      'og:title': itemData.title,
      'og:description': description,
      'og:image': imageUrl,
      'og:url': `https://sharedot.com/item/${itemData.snippetId}`,
      'og:type': 'website',
      'twitter:card': 'summary_large_image',
      'twitter:title': itemData.title,
      'twitter:description': description,
      'twitter:image': imageUrl
    };

    // Update document title
    document.title = itemData.title;

    // Update or create meta tags
    Object.entries(metaTags).forEach(([name, content]) => {
      let meta = document.querySelector(`meta[property="${name}"]`) ||
                 document.querySelector(`meta[name="${name}"]`);
      
      if (!meta) {
        meta = document.createElement('meta');
        if (name.startsWith('og:')) {
          meta.setAttribute('property', name);
        } else {
          meta.setAttribute('name', name);
        }
        document.head.appendChild(meta);
      }
      
      meta.setAttribute('content', content);
    });
  };

  // Update the existing useEffect that fetches item data
  useEffect(() => {
    const fetchItem = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/fetchItem/${id}`);
        const itemData = response.data;
        setItem(itemData);
        setAllImages(processImages(itemData));
        
        // Update metadata when item data is fetched
        updateMetadata(itemData);
      } catch (error) {
        console.error('Error fetching item:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItem();

    // Cleanup function
    return () => {
      // Reset meta tags to defaults when component unmounts
      document.title = 'ShareDot';
      const metaTags = [
        'og:title',
        'og:description',
        'og:image',
        'og:url',
        'twitter:card',
        'twitter:title',
        'twitter:description',
        'twitter:image'
      ];
      
      metaTags.forEach(name => {
        const meta = document.querySelector(`meta[property="${name}"]`) ||
                    document.querySelector(`meta[name="${name}"]`);
        if (meta) {
          meta.remove();
        }
      });
    };
  }, [id]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch feed items and ensure current item is included
  useEffect(() => {
    const initialize = async () => {
      try {
        setLoading(true);
        console.log('Initializing with id:', id);
  
        // Always fetch the specific item first
        const itemResponse = await axios.get(`/api/fetchItem/${id}`);
        const currentItemData = itemResponse.data;
        console.log('Fetched item data:', currentItemData);
  
        // Set the item immediately so we have data to display
        setItem(currentItemData);
        setAllImages(processImages(currentItemData));
  
        // Then handle feed integration as a separate step
        try {
          if (items.length === 0) {
            const feedResponse = await axios.get('/api/fetchFeedv2');
            let feedItems = feedResponse.data;
  
            // Check if our current item exists in feed
            const itemExists = feedItems.some(item => 
              String(item.snippetId) === String(id)
            );
  
            // If not, add it
            if (!itemExists) {
              feedItems = [{ snippetId: id, ...currentItemData }, ...feedItems];
            }
  
            setItems(feedItems);
          } else {
            // If we already have items but current item isn't included
            const itemExists = items.some(item => 
              String(item.snippetId) === String(id)
            );
  
            if (!itemExists) {
              setItems(prevItems => [
                { snippetId: id, ...currentItemData },
                ...prevItems
              ]);
            }
          }
        } catch (feedError) {
          console.error('Feed fetch error:', feedError);
          // If feed fails, at least ensure we have the current item
          setItems([{ snippetId: id, ...currentItemData }]);
        }
  
      } catch (error) {
        console.error('Error initializing:', error);
        setError('Failed to load item');
      } finally {
        setLoading(false);
      }
    };
  
    initialize();
  }, [id]); // Only depend on id
  
  
  // Handle arrow key navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!items.length) return;

      const currentIndex = items.findIndex(
        (item) => String(item.snippetId) === String(id)
      );

      if (currentIndex === -1) return; // Current id not in items

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        const nextIndex = currentIndex + 1;
        if (nextIndex >= items.length) {
          navigate(`/home`, { replace: true });
        } else {
          const nextId = items[nextIndex].snippetId;
          if (nextId !== id) {
            navigate(`/item/${nextId}`, { replace: true });
          }
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        const prevIndex = Math.max(currentIndex - 1, 0);
        const prevId = items[prevIndex].snippetId;
        if (prevId !== id) {
          navigate(`/item/${prevId}`, { replace: true });
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [id, items, navigate]);

  // Handle scroll wheel navigation
  const handleColumnWheel = (e) => {
    const scrollableColumn = e.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = scrollableColumn;
    const atTop = scrollTop === 0;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 1;
    const isScrollingDown = e.deltaY > 0;
    const isScrollingUp = e.deltaY < 0;

    // Only handle scroll if we're at bounds and not in cooldown
    if (((atBottom && isScrollingDown) || (atTop && isScrollingUp)) && !isTransitionCooldown) {
      e.preventDefault();

      const now = Date.now();
      const timeDelta = now - lastScrollTimeRef.current;
      lastScrollTimeRef.current = now;

      // Normalize scroll delta to prevent extreme values
      const normalizedDelta = Math.min(Math.abs(e.deltaY), 100) * (isScrollingDown ? 1 : -1);

      // Update velocity with more controlled acceleration
      const velocityChange = (normalizedDelta / timeDelta) * (isScrollingDown ? 1 : -1);
      scrollVelocityRef.current = (scrollVelocityRef.current + velocityChange) * VELOCITY_DECAY;

      // Update progress with normalized delta
      scrollDeltaRef.current += Math.abs(normalizedDelta);
      const progress = Math.min(scrollDeltaRef.current / FRICTION_THRESHOLD, 1);
      setScrollProgress(progress);

      // Clear existing animation frame
      if (scrollAnimationRef.current) {
        cancelAnimationFrame(scrollAnimationRef.current);
      }

      const animateScroll = () => {
        if (Math.abs(scrollVelocityRef.current) > MIN_VELOCITY) {
          scrollVelocityRef.current *= VELOCITY_DECAY;
          scrollDeltaRef.current += Math.abs(scrollVelocityRef.current);

          const progress = Math.min(scrollDeltaRef.current / FRICTION_THRESHOLD, 1);
          setScrollProgress(progress);

          if (progress >= 1) {
            // Trigger page change and set cooldown
            setIsTransitionCooldown(true);

            const currentIndex = items.findIndex(
              (item) => String(item.snippetId) === String(id)
            );

            if (currentIndex !== -1) {
              const newIndex = isScrollingDown
                ? currentIndex + 1
                : Math.max(currentIndex - 1, 0);

              if (newIndex >= items.length) {
                navigate(`/home`, { replace: true });
              } else {
                const newId = items[newIndex].snippetId;
                if (newId !== id) {
                  navigate(`/item/${newId}`, { replace: true });
                }
              }
            }

            // Reset states
            resetScrollState();

            // Clear cooldown after delay
            setTimeout(() => {
              setIsTransitionCooldown(false);
            }, TRANSITION_COOLDOWN);

            return;
          }

          scrollAnimationRef.current = requestAnimationFrame(animateScroll);
        } else {
          resetScrollState();
        }
      };

      scrollAnimationRef.current = requestAnimationFrame(animateScroll);
    }
  };

  const resetScrollState = () => {
    scrollVelocityRef.current = 0;
    scrollDeltaRef.current = 0;
    setScrollProgress(0);
    setShowTransitionIndicator(null);

    if (scrollAnimationRef.current) {
      cancelAnimationFrame(scrollAnimationRef.current);
    }
  };

  // Cleanup
  useEffect(() => {
    return () => {
      if (scrollAnimationRef.current) {
        cancelAnimationFrame(scrollAnimationRef.current);
      }
    };
  }, []);

  // Constants for scroll behavior
  const FRICTION_THRESHOLD = window.innerHeight * 0.6; // 40% of viewport height
  const VELOCITY_DECAY = 0.92; // How quickly velocity decreases
  const MIN_VELOCITY = 4; // Minimum velocity to continue momentum
  const TRANSITION_COOLDOWN = 500; // Add cooldown between transitions (ms)

  // Original processImages function -----
  // // Process images
  // const processImages = (item) => {
  //   const images = [];

  //   // Add images from imageResults (limit to first 6)
  //   if (item.imageResults && Array.isArray(item.imageResults)) {
  //     images.push(
  //       ...item.imageResults.slice(0, 6).map((img) => ({
  //         url: img.original,
  //         source: img.source,
  //         type: 'item_image',
  //         link: img.link,
  //       }))
  //     );
  //   }

  //   // Add images from citations (limit remaining slots up to 12)
  //   if (item.snippetDetail?.search_citations?.citations) {
  //     const remainingSlots = 12 - images.length;
  //     const citationImages = item.snippetDetail.search_citations.citations
  //       .filter((citation) => citation.citation_OG_Image_URL)
  //       .slice(0, remainingSlots)
  //       .map((citation) => ({
  //         url: citation.citation_OG_Image_URL,
  //         source: extractDomainName(citation.citation_URL),
  //         type: 'citation_image',
  //         link: citation.citation_URL,
  //       }));
  //     images.push(...citationImages);
  //   }

  //   return images;
  // };

  const isBlockedDomain = (urlString: string): boolean => {
    const blockedDomains = [
      'facebook.com',
      'instagram.com',
      'fb.com',
      'fbcdn.net',
      'academia.edu',
      'academia-photos.com',
      'library.fiveable.me',
      'stjohngrimbly.com',
      'pnas.com',
      'pmc.ncbi.nlm.nih.gov',
      'fiveable.me',
      'researchgate.net',
      'pubmed.ncbi.nlm.nih.gov',
      'stars.astro.illinois.edu',
      'tiktok.com',
      'tiktokcdn.com',
      'tiktokcdn.net',
      'tiktokcdn.org',
    ];
  
    try {
      const url = new URL(urlString.toLowerCase());
      const host = url.hostname;
  
      // Check if URL ends with .svg or contains .svg in path or query
      if (url.pathname.toLowerCase().includes('.svg') ||
          url.search.toLowerCase().includes('.svg')) {
        return true;
      }
  
      // Check for empty or placeholder images
      if (url.pathname.toLowerCase().includes('placeholder') ||
          url.pathname.toLowerCase().includes('empty') ||
          url.pathname.toLowerCase().includes('default')) {
        return true;
      }
  
      // Check file size in URL if present
      if (url.search.includes('mini_magick') ||
          url.pathname.includes('mini_magick')) {
        return true;
      }
  
      // Check if the host ends with any blocked domain
      return blockedDomains.some(domain => host.endsWith(domain));
    } catch {
      return false;
    }
  };

  const checkImageExists = async (urlString: string): Promise<boolean> => {
    try {
      const response = await fetch(urlString, { 
        method: 'HEAD',
        mode: 'no-cors' // Try with no-cors mode
      });
      return response.ok;
    } catch (error) {
      console.log('Image fetch error:', error);
      return false;
    }
  };
  
  const isValidImageUrl = async (urlString: string): Promise<boolean> => {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'cms'];
    
    try {
      const url = new URL(urlString);
      const path = url.pathname.toLowerCase();
      
      // Check file extension
      const extension = path.split('.').pop();
      if (extension && !allowedExtensions.includes(extension.toLowerCase())) {
        return false;
      }
      
      // Try loading the image
      return await checkImageExists(urlString);
    } catch {
      return false;
    }
  };
  
  const processImages = (item) => {
    const images = [];
  
    // Add images from imageResults (limit to first 6)
    if (item.imageResults && Array.isArray(item.imageResults)) {
      const validImageResults = item.imageResults
        .filter(img => {
          const imageUrl = img.original;
          const linkUrl = img.link;
          return imageUrl && linkUrl &&
                 !isBlockedDomain(imageUrl) &&
                 !isBlockedDomain(linkUrl) &&
                 isValidImageUrl(imageUrl);
        })
        .slice(0, 6)
        .map((img) => ({
          url: img.original,
          source: img.source,
          type: 'item_image',
          link: img.link,
        }));
      
      images.push(...validImageResults);
    }
  
    // Add images from citations (limit remaining slots up to 12)
    if (item.snippetDetail?.search_citations?.citations) {
      const remainingSlots = 12 - images.length;
      const citationImages = item.snippetDetail.search_citations.citations
        .filter(citation => {
          const imageUrl = citation.citation_OG_Image_URL;
          const linkUrl = citation.citation_URL;
          return imageUrl && linkUrl &&
                 !isBlockedDomain(imageUrl) &&
                 !isBlockedDomain(linkUrl) &&
                 isValidImageUrl(imageUrl);
        })
        .slice(0, remainingSlots)
        .map((citation) => ({
          url: citation.citation_OG_Image_URL,
          source: extractDomainName(citation.citation_URL),
          type: 'citation_image',
          link: citation.citation_URL,
        }));
      
      images.push(...citationImages);
    }
  
    return images;
  };

  // Compute currentIndex, isFirst, isLast for navigation
  const currentIndex = items.findIndex(
    (item) => String(item.snippetId) === String(id)
  );

  const isFirst = currentIndex <= 0;
  const isLast = currentIndex >= items.length - 1;

    // Navigate to home
    const handleHome = () => {
        navigate('/home');
    };

  // // Navigation works but is not circular and stops at the last item
  // const handleNext = () => {
  //   if (!items.length) return;
  //   const nextIndex = (currentIndex + 1) % items.length;
  //   const nextId = items[nextIndex].snippetId;
  //   navigate(`/item/${nextId}`, { replace: true });
  // };

  // const handlePrev = () => {
  //   if (!items.length) return;
  //   const prevIndex = (currentIndex - 1 + items.length) % items.length;
  //   const prevId = items[prevIndex].snippetId;
  //   navigate(`/item/${prevId}`, { replace: true });
  // };

  // Navigation that is circular
  const handleNext = () => {
    if (!items.length) return;
    // Use modulo to wrap around
    const nextIndex = (currentIndex + 1) % items.length;
    const nextId = items[nextIndex].snippetId;
    navigate(`/item/${nextId}`, { replace: true });
  };
  
  const handlePrev = () => {
    if (!items.length) return;
    // Use (currentIndex - 1 + items.length) to ensure positive modulo
    const prevIndex = (currentIndex - 1 + items.length) % items.length;
    const prevId = items[prevIndex].snippetId;
    navigate(`/item/${prevId}`, { replace: true });
  };

  // Add this component for visual feedback
  const ScrollProgressIndicator = ({ progress, direction }) => (
    <Box
      position="fixed"
      right={6}
      top="50%"
      transform="translateY(-50%)"
      height="200px"
      width="4px"
      bg="whiteAlpha.200"
      borderRadius="full"
      overflow="hidden"
    >
      <Box
        position="absolute"
        bottom={direction === 'up' ? '0' : 'auto'}
        top={direction === 'down' ? '0' : 'auto'}
        left="0"
        width="100%"
        height={`${progress * 100}%`}
        bg="white"
        transition="height 0.1s ease-out"
        borderRadius="full"
      />
    </Box>
  );

  const ImageWithFallback = ({ image, onImageClick, index }) => {
    const [hasError, setHasError] = useState(false);
  
    return (
      <Box
        position="relative"
        cursor="pointer"
        onClick={onImageClick}
        borderRadius="md"
        overflow="hidden"
        aspectRatio="1"
        transition="transform 0.2s"
        _hover={{ transform: 'scale(1.02)' }}
      >
        {!hasError && image.url ? (
          <Image
            src={image.url}
            alt={`Gallery image ${index + 1}`}
            objectFit="cover"
            w="full"
            h="full"
            onError={() => setHasError(true)}
          />
        ) : (
          <ImageFallback source={image.source} />
        )}
        <Box
          position="absolute"
          bottom={2}
          left="50%"
          transform="translateX(-50%)"
        >
          <Text
            color="white"
            fontSize="xs"
            bg="blackAlpha.700"
            px={2}
            py={1}
            borderRadius="md"
          >
            {image.source}
          </Text>
        </Box>
      </Box>
    );
  };

      useEffect(() => {
        const handleNavigation = async (direction) => {
          setIsTransitioning(true);
          const newIndex = direction === 'next' 
            ? Math.min(currentItemIndex + 1, items.length - 1)
            : Math.max(currentItemIndex - 1, 0);
          
          setCurrentItemIndex(newIndex);
          
          // Allow time for exit animation before starting new fetch
          setTimeout(() => {
            setIsTransitioning(false);
          }, 300);
        };
      }, []); // Add appropriate dependencies
    
      useEffect(() => {
        const fetchItem = async () => {
          try {
            setLoading(true);
            const response = await axios.get(`/api/fetchItem/${id}`);
            setItem(response.data);
            setAllImages(processImages(response.data));
          } catch (error) {
            console.error('Error fetching item:', error);
            setError(error);
          } finally {
            setLoading(false);
          }
        };
        fetchItem();
      }, [id]);
  
      if (loading) {
        return <ItemDetailLoading isMobile={isMobile} />;
      }
    
      if (error || !item) {
        return (
          <Box>
            <Header />
            <Center h="calc(100vh - 64px)" bg="black">
              <Text color="white">Error loading item.</Text>
            </Center>
          </Box>
        );
      }
    
  // Update the mobile layout to use the new component:
  if (isMobile) {
    return (
      <Box bg="black" minH="100vh" position="relative">
        <Header />
        <AnimatePresence mode="wait">
          <motion.div
            key={item?.snippetId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {/* Content Container - Add paddingBottom to account for nav bar */}
            <Box maxW="100%" mx="auto" pb="80px"> {/* Increased padding bottom */}
              {/* Featured Image Section */}
              {allImages.length > 0 && (
                <FeaturedImageContainer
                  image={allImages[0]}
                  totalImages={allImages.length}
                  onClick={() => {
                    setCurrentImageIndex(0);
                    setIsGalleryOpen(true);
                  }}
                />
              )}
  
              {/* Content Container */}
              <VStack 
                spacing={6} 
                px={4} 
                align="stretch"
                position="relative"
                zIndex={1}
                bg="black"
              >
                {/* Title */}
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="white"
                  textAlign="left"
                  lineHeight="1.2"
                >
                  {item.title}
                </Text>

                {/* Description */}
                <Text 
                  color="gray.300"
                  fontSize="md"
                  //lineHeight="1.3"
                  textAlign="left"
                >
                  {item.content}
                </Text>

                {/* Search Summary */}
                {item.snippetDetail?.search_summary && (
                  <Box>
                    <ProcessedText 
                      text={item.snippetDetail.search_summary}
                      citations={item.snippetDetail?.search_citations?.citations}
                    />
                  </Box>
                )}

                {/* Q&A Section */}
                {item.snippetDetail?.long_description?.results && (
                  <Box>
                    <SectionTitle mb={3}>Related</SectionTitle>
                    <Accordion allowMultiple>
                      {item.snippetDetail.long_description.results.map((qa, index) => (
                        <AccordionItem 
                          key={index} 
                          border="none"
                          bg="whiteAlpha.50"
                          mb={3}
                          borderRadius="md"
                        >
                          <AccordionButton 
                            py={3}
                            px={4}
                            _hover={{ bg: "whiteAlpha.100" }}
                          >
                            <Text 
                              color="white"
                              fontSize="md"
                              fontWeight="500"
                              textAlign="left"
                              flex={1}
                            >
                              {qa.question}
                            </Text>
                            <ChevronDownIcon color="white" />
                          </AccordionButton>
                          <AccordionPanel pb={4} px={4}>
                            <Text color="gray.300" fontSize="md" textAlign="left">
                              {qa.answer}
                            </Text>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </Box>
                )}

                {/* Citations */}
                {item.snippetDetail?.search_citations?.citations && (
                <Box mb={16}> {/* Added margin bottom */}
                  <SectionTitle mb={3}>Sources</SectionTitle>
                  <VStack spacing={2}>
                    {item.snippetDetail.search_citations.citations.map((citation, index) => (
                      <CitationCard key={index} citation={citation} />
                    ))}
                  </VStack>
                </Box>
              )}
            </VStack>
          </Box>
        </motion.div>
      </AnimatePresence>

        <MobileNavBar
          onNext={handleNext}
          onPrev={handlePrev}
          onHome={handleHome}
        />

        {allImages.length > 0 && (
          <ImageGallery
            isOpen={isGalleryOpen}
            onClose={() => setIsGalleryOpen(false)}
            currentImageIndex={currentImageIndex}
            setCurrentImageIndex={setCurrentImageIndex}
            images={allImages}
          />
        )}
      </Box>
    );
  }
    
  return (
    <Box bg="black" minH="100vh" fontFamily="Inter, sans-serif">
      <Header position="fixed" top={0} left={0} right={0} zIndex={10} />
      <AnimatePresence mode="wait">
        <motion.div
          key={item?.snippetId}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Box
            position="relative"
            pt="48px" // Height of header
            pl="200px" // Width of sidebar
          >
            <Flex
              position="fixed"
              top="48px"
              left="200px"
              right="0"
              bottom="0"
              overflow="hidden"
            >
              {/* Column 1: Main Content */}
              <Box
                className="scrollable-column"
                flex="1.5"
                height="100%"
                overflowY="auto"
                borderRight="1px solid"
                borderColor="whiteAlpha.200"
                onWheelCapture={handleColumnWheel}
                css={{
                  '&::-webkit-scrollbar': { width: '4px' },
                  '&::-webkit-scrollbar-track': { background: 'transparent' },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '2px',
                  },
                }}
              >
                <Box px={8}>
                  <VStack align="stretch" spacing={8} py={8}>
                    {/* Featured Image */}
                    {allImages.length > 0 && (
                      <FeaturedImage 
                        image={allImages[0]}
                        onImageClick={() => {
                          setCurrentImageIndex(0);
                          setIsGalleryOpen(true);
                        }}
                      />
                    )}

                    {/* Title */}
                    <Text
                      fontSize="32px"
                      fontWeight="bold"
                      color="white"
                      textAlign="left"
                      w="full"
                      lineHeight="1.2"
                    >
                      {item.title}
                    </Text>

                    {/* Search Summary */}
                    {item.snippetDetail?.search_summary && (
                      <Box w="full">
                        <ProcessedText 
                          text={item.snippetDetail.search_summary}
                          citations={item.snippetDetail?.search_citations?.citations}
                        />
                      </Box>
                    )}

                    {/* Q&A Section */}
                    {item.snippetDetail?.long_description?.results && (
                      <Box w="full">
                        <SectionTitle mb={4}>Related</SectionTitle>
                        <Accordion allowMultiple>
                          {item.snippetDetail.long_description.results.map((qa, index) => (
                            <AccordionItem 
                              key={index} 
                              border="none" 
                              bg="whiteAlpha.50" 
                              mb={3} 
                              borderRadius="md"
                            >
                              <AccordionButton 
                                py={4} 
                                px={6}
                                _hover={{ bg: "whiteAlpha.100" }}
                                borderRadius="md"
                              >
                                <Text 
                                  color="white" 
                                  fontSize="16px"
                                  fontWeight="500"
                                  textAlign="left"
                                  flex={1}
                                >
                                  {qa.question}
                                </Text>
                                <ChevronDownIcon color="white" />
                              </AccordionButton>
                              <AccordionPanel pb={4} px={6}>
                                <Text color="gray.300" fontSize="16px" textAlign="left" lineHeight="1.7">
                                  {qa.answer}
                                </Text>
                              </AccordionPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </Box>
                    )}

                    {/* Citations/Sources */}
                    {item.snippetDetail?.search_citations?.citations && (
                      <Box w="full" mb={8}>
                        <SectionTitle mb={4}>Sources</SectionTitle>
                        <VStack spacing={2}>
                          {item.snippetDetail.search_citations.citations.map((citation, index) => (
                            <CitationCard key={index} citation={citation} />
                          ))}
                        </VStack>
                      </Box>
                    )}
                  </VStack>
                </Box>
              </Box>

              {/* Column Divider with Navigation */}
              <Box
                position="relative"
                width="1px"
                bg="whiteAlpha.200"
                mx={0}
              >
                <VStack
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  spacing={4}
                >
                  <IconButton
                    icon={<ChevronUpIcon boxSize={5} />}
                    aria-label="Previous item"
                    onClick={handlePrev}
                    width="40px"
                    height="40px"
                    bg="transparent"
                    color="white"
                    borderRadius="md"
                    sx={{
                      '&:hover': {
                        bg: 'white',
                        '& svg': {
                          color: 'black',
                        },
                      },
                      '&:active': {
                        bg: 'whiteAlpha.800',
                      },
                    }}
                  />
                  <IconButton
                    icon={<ChevronDownIcon boxSize={5} />}
                    aria-label="Next item"
                    onClick={handleNext}
                    width="40px"
                    height="40px"
                    bg="transparent"
                    color="white"
                    borderRadius="md"
                    sx={{
                      '&:hover': {
                        bg: 'white',
                        '& svg': {
                          color: 'black',
                        },
                      },
                      '&:active': {
                        bg: 'whiteAlpha.800',
                      },
                    }}
                  />
                </VStack>
              </Box>


              {/* Column 2: Image Grid */}
              <Box
                className="scrollable-column"
                flex="1"
                height="100%"
                overflowY="auto"
                onWheelCapture={handleColumnWheel}
                css={{
                  '&::-webkit-scrollbar': { width: '4px' },
                  '&::-webkit-scrollbar-track': { background: 'transparent' },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '2px',
                  },
                }}
              >
                <Box px={8}>
                  <SimpleGrid
                    columns={2}
                    spacing={4}
                    py={8}
                  >
                    {allImages.slice(0, 12).map((image, index) => (
                      <ImageWithFallback
                        key={index}
                        image={image}
                        index={index}
                        onImageClick={() => {
                          setCurrentImageIndex(index);
                          setIsGalleryOpen(true);
                        }}
                      />
                    ))}
                  </SimpleGrid>
                </Box>
              </Box>
            </Flex>

            {/* Image Gallery Modal */}
            {allImages.length > 0 && (
              <ImageGallery
                isOpen={isGalleryOpen}
                onClose={() => setIsGalleryOpen(false)}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                images={allImages}
              />
            )}
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default ItemDetail;