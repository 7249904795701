import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  useToast,
  Spinner,
  Grid
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../auth/firebaseConfig.ts'; // Import auth instance from your config
import { useAuth } from '../auth/AuthContext'; // Update path as needed
import Cookies from 'js-cookie';
import Logo from '../../public/assets/sharedot_logo.svg';
import CustomIcon from '../../public/assets/menuButtonCircles.svg';



const Header = () => {
  const { authState, setAuthInfo } = useAuth(); // Use the auth context instead of props
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  
  const menuItems = [
    { name: 'Discover', href: '/' },
    { name: 'Ask', href: '/ask' },
    { name: 'Notes', href: '/notes' },
  //  { name: 'Ask History', href: '/askhistory' },
   // { name: 'About', href: '/about' },
  ];

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setAuthInfo({ 
        userId: null,
        isAuthenticated: false,
        displayNameCompleted: false,
        onboardingQuestionsCompleted: false
      });
      Cookies.remove('userId');
      Cookies.remove('sessionToken');
      Cookies.remove('emailForSignIn');
      navigate('/');
    } catch (error) {
      console.error("Sign out error:", error);
    }
  };

  const SignInButton = ({ isMobile = false }) => (
    <Button
      onClick={() => navigate('/login')}
      bg="white"
      color="black"
      size={{ base: "md", md: "sm" }}
      fontWeight="medium"
      width={isMobile ? "80%" : "auto"}
      _hover={{
        bg: "gray.100"
      }}
    >
      Sign In
    </Button>
  );

  const SignOutButton = ({ isMobile = false }) => (
    <Button
      onClick={handleSignOut}
      variant="outline"
      bg="black"
      color="white"
      borderColor="white"
      size={{ base: "md", md: "sm" }}
      fontWeight="medium"
      width={isMobile ? "80%" : "auto"}
      _hover={{
        bg: "gray.900"
      }}
    >
      Sign Out
    </Button>
  );

  return (
    <Box 
      as="header" 
      position="fixed"
      top="0"
      left="0"
      right="0"
      height={{ base: "48px", md: "64px" }} // Reduced height for mobile
      zIndex="1000"
      bg="black"
      borderBottom="1px solid"
      borderColor="whiteAlpha.200"
    >
      <Flex
        height="100%"
        px={4}
        align="center"
        justify="space-between"
        maxW="100%"
      >
        <Flex align="center" height="100%">
        <Link to="/home">
          <img 
            src={Logo} 
            alt="SHAREDOT"
            style={{ 
              height: '18px',
              marginLeft: '16px',
              display: 'block',
            }} 
          />
        </Link>
        </Flex>

        {/* Desktop Auth Button */}
        <Box display={{ base: 'none', md: 'block' }}>
          {authState.userId ? <SignOutButton /> : <SignInButton />}
        </Box>

        {/* Mobile menu button */}
        <Flex 
          display={{ base: 'flex', md: 'none' }}
          align="center"
          height="100%"
        >
          <Button 
            variant="ghost" 
            onClick={onDrawerOpen}
            p={0}
            minW="32px"
            height="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ bg: 'transparent' }}
          >
            <img 
              src={CustomIcon} 
              alt="Menu"
              style={{ 
                height: '24px', // Slightly reduced icon size
                width: '24px',
              }} 
            />
          </Button>
        </Flex>
      </Flex>

      {/* Desktop Sidebar */}
      <Box
        display={{ base: 'none', md: 'block' }}
        position="fixed"
        left={0}
        top="64px"
        height="calc(100vh - 64px)"
        width="200px"
        bg="black"
        borderRight="1px solid"
        borderColor="whiteAlpha.200"
        zIndex="999"
      >
        <VStack spacing={0} pt={8} align="stretch">
          {menuItems.map((item) => (
            <Box
              key={item.name}
              as="a"
              href={item.href}
              color="white"
              px={6}
              py={4}
              _hover={{
                color: 'black',
                bg: 'white',
                borderRadius: '4px', // Adds slightly rounded corners
                border: '1px solid black', // Adds black stroke
                mx: '8px', // Add horizontal margin to prevent hover box from touching edges
                transition: 'none'
              }}
              fontFamily="Inter, sans-serif"
              fontSize="md"
              fontWeight="semibold"
              width="100%"
              display="block"
              textAlign="left"
            >
              {item.name}
            </Box>
          ))}
        </VStack>
      </Box>

      {/* Mobile Drawer */}
      <Drawer isOpen={isDrawerOpen} onClose={onDrawerClose} placement="right">
        <DrawerOverlay />
        <DrawerContent bg="black">
          <DrawerCloseButton color="gray.300" />
          <DrawerBody display="flex" flexDirection="column">
            <VStack spacing={4} mt={12} flex="1" align="stretch">
              {menuItems.map((item) => (
                <Box
                  key={item.name}
                  as="a"
                  href={item.href}
                  fontSize="lg"
                  color="gray.300"
                  _hover={{ color: 'white' }}
                  w="100%"
                  transition="colors 0.2s"
                  fontFamily="Inter, sans-serif"
                  fontWeight="medium"
                  px={6}
                >
                  {item.name}
                </Box>
              ))}
            </VStack>
            <Box w="100%" mb={16} display="flex" justifyContent="center" mt="auto">
              {authState.userId ? <SignOutButton isMobile /> : <SignInButton isMobile />}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;