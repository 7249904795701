// components/FeedView.js
import React, { useEffect, useState, useCallback } from 'react';
import { 
  Box, 
  VStack, 
  HStack, 
  Button, 
  Center, 
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tag,
  Wrap,
  Input,
  TagLabel,
  TagCloseButton
} from '@chakra-ui/react';
import axios from 'axios';
import Header from './Header';
import Masonry from 'react-masonry-css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import withFirebaseAuth from '../utils/with_firebase_auth';


const CONFIG = {
  imageLoadTimeout: 2000, 
  maxRetries: 2, 
  retryDelay: 2000, 
};

const masonryStyles = `
.masonry-grid {
  display: flex;
  width: 100%;
  margin-left: -8px;
  background-color: black;
}
.masonry-grid_column {
  padding-left: 8px;
  background-clip: padding-box;
}
`;

const shimmerStyles = `
.shimmer-container {
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`;

const ShimmerLoadingCard = () => (
  <Box 
    bg="gray.900"
    borderRadius="md"
    overflow="hidden"
    mb={2}
  >
    <Box 
      className="shimmer-container" 
      h={{ base: "160px", md: "240px" }}
    >
      <Box className="shimmer-effect" />
    </Box>
    
    <VStack align="stretch" p={3} spacing={1}>
      <Box 
        className="shimmer-container" 
        h="16px" 
        w="90%"
        borderRadius="md"
      >
        <Box className="shimmer-effect" />
      </Box>
      
      <Box 
        className="shimmer-container" 
        h="13px" 
        w="70%"
        borderRadius="md"
      >
        <Box className="shimmer-effect" />
      </Box>
      
      <HStack justify="space-between" pt={1}>
        <Box 
          className="shimmer-container"
          h="12px" 
          w="40%"
          borderRadius="full"
        >
          <Box className="shimmer-effect" />
        </Box>
      </HStack>
    </VStack>
  </Box>
);

const LoadingState = () => {
  const breakpointCols = {
    default: 2,
    1100: 2,
    700: 2
  };

  return (
    <Box>
      <Header />
      <style>
        {`
          .shimmer-container {
            position: relative;
            overflow: hidden;
            background: #1a1a1a;
          }

          .shimmer-effect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.1) 50%,
              rgba(255, 255, 255, 0) 100%
            );
            animation: shimmer 1.2s infinite linear;
          }

          @keyframes shimmer {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }

          .masonry-grid {
            display: flex;
            width: 100%;
            margin-left: -8px;
            background-color: black;
          }
          .masonry-grid_column {
            padding-left: 8px;
            background-clip: padding-box;
          }
        `}
      </style>
      
      <Box minH="100vh" bg="black">
        <Box 
          pt="60px"
          minH="calc(100vh - 60px)"
          bg="black"
        >
          <Box px={{ base: 2, md: 8, lg: 24, xl: 48 }} py={3}>
            <Box 
              maxW={{ base: "100%", md: "800px" }} 
              mx="auto"
            >
              <Masonry
                breakpointCols={breakpointCols}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
              >
                {[...Array(6)].map((_, index) => (
                  <ShimmerLoadingCard key={index} />
                ))}
              </Masonry>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};


const FILTERED_IMAGE_DOMAINS = [
  'facebook.com',
  'instagram.com',
  'fb.com',
  'fbcdn.net',
  'academia.edu',
  'academia-photos.com',
  'library.fiveable.me',
  'stjohngrimbly.com',
  'pnas.com',
  'researchgate.net',
  'pmc.ncbi.nlm.nih.gov',
  'pubmed.ncbi.nlm.nih.gov',
  'stars.astro.illinois.edu',

];

const isBlockedDomain = (url) => {
  if (!url) return true;
  return FILTERED_IMAGE_DOMAINS.some(domain => 
    url.toLowerCase().includes(domain.toLowerCase())
  );
};

const isValidImageUrl = (url) => {
  if (!url) return false;
  
  try {
    const urlObj = new URL(url);
    const fullPath = urlObj.pathname + urlObj.search;
    const hasImageExtension = /\.(jpe?g|gif|png|webp|bmp)($|\?)/i.test(fullPath);
    const isImageHost = [
      'res.com',
      'alamy.com',
      'wp.com',
      'mdpi',
      'images',
      'img',
      'photos',
      'media'
    ].some(host => urlObj.hostname.toLowerCase().includes(host));
    
    return hasImageExtension || isImageHost;
  } catch (e) {
    return /\.(jpe?g|gif|png|webp|bmp)($|\?)/i.test(url);
  }
};

const ImagePreloader = ({ src, onLoad, onError }) => {
  useEffect(() => {
    let timeoutId;
    let retryCount = 0;
    let isActive = true;

    const loadImage = () => {
      const img = new Image();
      
      const handleLoad = () => {
        if (isActive) {
          clearTimeout(timeoutId);
          onLoad();
        }
      };

      const handleError = () => {
        clearTimeout(timeoutId);
        if (isActive) {
          if (retryCount < CONFIG.maxRetries) {
            retryCount++;
            setTimeout(loadImage, CONFIG.retryDelay);
          } else {
            onError();
          }
        }
      };

      timeoutId = setTimeout(() => {
        if (isActive) {
          if (retryCount < CONFIG.maxRetries) {
            retryCount++;
            loadImage();
          } else {
            onError();
          }
        }
      }, CONFIG.imageLoadTimeout);

      img.onload = handleLoad;
      img.onerror = handleError;
      img.src = src;
    };

    loadImage();

    return () => {
      isActive = false;
      clearTimeout(timeoutId);
    };
  }, [src, onLoad, onError]);
  
  return null;
};

const SnippetCard = ({ snippet }) => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [validImages, setValidImages] = useState([]);
  const [shouldShow, setShouldShow] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [loadAttempts, setLoadAttempts] = useState({});

  useEffect(() => {
    if (snippet.imageResults && Array.isArray(snippet.imageResults)) {
      const processedImages = snippet.imageResults
        .filter(img => {
          const imageUrl = img.original;
          const linkUrl = img.link;
          return imageUrl && linkUrl &&
                 !isBlockedDomain(imageUrl) &&
                 !isBlockedDomain(linkUrl) &&
                 isValidImageUrl(imageUrl);
        })
        .slice(0, 6)
        .map((img) => ({
          url: img.original,
          source: img.source,
          type: 'item_image',
          link: img.link,
        }));

      setValidImages(processedImages);
      setShouldShow(processedImages.length > 0);
      setIsImageLoaded(false);
      setLoadAttempts({});
    } else {
      setShouldShow(false);
    }
  }, [snippet.imageResults]);

  const handleClick = useCallback(() => {
    navigate(`/item/${snippet.snippetId}`);
  }, [navigate, snippet.snippetId]);

  const handleImageLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  const handleImageError = useCallback(() => {
    setLoadAttempts(prev => ({
      ...prev,
      [currentImageIndex]: (prev[currentImageIndex] || 0) + 1
    }));

    if ((loadAttempts[currentImageIndex] || 0) >= CONFIG.maxRetries) {
      if (currentImageIndex < validImages.length - 1) {
        setCurrentImageIndex(prev => prev + 1);
        setIsImageLoaded(false);
      } else {
        setShouldShow(false);
      }
    }
  }, [currentImageIndex, validImages.length, loadAttempts]);

  if (!shouldShow) {
    return null;
  }

  const currentImage = validImages[currentImageIndex];
  if (!currentImage) {
    return null;
  }

  return (
    <>
      <style>{shimmerStyles}</style>
      <Box 
        onClick={handleClick}
        cursor="pointer"
        bg="black" 
        borderRadius="md"
        overflow="hidden"
        transition="all 0.2s"
        _hover={{ transform: 'translateY(-2px)', shadow: 'sm' }}
        mb={2}
      >
        <ImagePreloader
          src={currentImage.url}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
        
        <Box 
          h={{ base: "160px", md: "240px" }}
          position="relative"
          overflow="hidden"
        >
          {!isImageLoaded ? (
            <Box className="shimmer-container" height="100%">
              <Box className="shimmer-effect" />
            </Box>
          ) : (
            <img 
              src={currentImage.url} 
              alt={snippet.title}
              loading="lazy"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          )}
        </Box>

        <VStack align="stretch" p={3} spacing={1}>
          <Box 
            color="white" 
            fontSize={{ base: "14px", md: "16px" }}
            fontWeight={{ base: "500", md: "600" }}
            noOfLines={2}
            textAlign="left"
            lineHeight="1.4"
          >
            {snippet.title}
          </Box>

          <Box 
            color="gray.400" 
            fontSize={{ base: "12px", md: "13px" }}
            noOfLines={0}
            textAlign="left"
            lineHeight="1.4"
          >
            {snippet.content}
          </Box>

          <HStack justify="space-between">
            <Box
              bg="whiteAlpha.200"
              px={2}
              py={0.5}
              borderRadius="full"
              fontSize={{ base: "10px", md: "12px" }}
              color="white"
              isTruncated
              maxW="45ch"
              textAlign="left"
            >
              {snippet.topicTitle || 'Default Topic'}
            </Box>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

const FeedView = () => {
  const [snippets, setSnippets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cursor, setCursor] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const breakpointCols = {
    default: 2,
    1100: 2,
    700: 2
  };

  const fetchSnippets = useCallback(async () => {
    try {
      const response = await fetch(`/api/fetchFeedv3?cursor=${cursor || ''}&limit=20`);
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status} - ${response.statusText}`);
      }
      const data = await response.json();
      const { items, pagination } = data;
    
      console.log("Fetched items:", items.length);
      console.log("Has more items:", pagination.hasMore);
      console.log("Next cursor from backend:", pagination.nextCursor);
    
      setSnippets((prev) => [...prev, ...items]);
      setHasMore(pagination.hasMore);
      setCursor(pagination.nextCursor);
    } catch (err) {
      console.error("Error fetching snippets:", err.message, err.stack);
    }
     finally {
      setLoading(false);
    }
  }, [cursor]);
  
  useEffect(() => {
    if (!loading && hasMore) {
      console.log("Triggering fetchSnippets...");
      fetchSnippets();
    }
  }, [fetchSnippets, loading, hasMore]);
  

  if (error) {
    return <Box>Error: {error}</Box>;
  }

  if (loading && snippets.length === 0) {
    return <LoadingState />;
  }

  // Add isLoggedIn check
  const isLoggedIn = !!Cookies.get('userId');

  const handleLoginClick = () => {
    onClose(); // Close the modal first
    navigate('/login'); // Navigate to login page
  };

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return (
      <>
        <Header />
        <Center h="100vh" color="white">
          {error}
        </Center>
      </>
    );
  }

  return (
    <Box>
      <Header />
      <style>{masonryStyles}</style>
      <Box bg="black" px={{ base: 2, md: 8, lg: 24, xl: 48 }} py={3}>
        {/* <HStack justifyContent="flex-start" mb={4}>
          <Button 
            onClick={onOpen} 
            bg="whiteAlpha.200" 
            color="white" 
            _hover={{ bg: 'whiteAlpha.300' }}
            size="sm"
          >
            Select Interests
          </Button>
        </HStack> */}

        <Box
          // ml={{ base: 0, md: "240px" }}  // Account for sidebar width on desktop
          pt="60px"  // Account for header height
          minH="calc(100vh - 60px)"
          bg="black"
          transition="margin-left 0.3s"
      >
        <Box 
          px={{ base: 2, md: 8, lg: 24, xl: 48 }} 
          py={3}
        >
          <Box 
            maxW={{ base: "100%", md: "800px" }} 
            mx="auto"
          >
            <Masonry
              breakpointCols={breakpointCols}
              className="masonry-grid"
              columnClassName="masonry-grid_column"
            >
              {snippets
                .filter(snippet => snippet.imageResults?.length > 0)
                .map(snippet => (
                  <SnippetCard key={snippet.snippetId} snippet={snippet} />
                ))}
            </Masonry>
          </Box>
        </Box>
      </Box>
    </Box>
    </Box>
  );
};

export default FeedView;