import React, { useState, useRef, useEffect, useCallback } from 'react';
import { 
  Box, 
  Flex, 
  Text, 
  Spinner,
  useMediaQuery,
  Icon,
  Button,
  useDisclosure,
  InputGroup,
  Image,
  VStack,
  Grid,
  HStack
} from '@chakra-ui/react';
import { ArrowForwardIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import Markdown from "react-markdown";
import Cookies from 'js-cookie';
import { useAuth } from "../auth/AuthContext";
import Login from "./Login";
import { AutoResizeTextarea } from './AutoResizeTextarea';
import QANotesView from './AskHistory';
import { FaUtensils, FaMapMarkerAlt, FaCommentAlt, FaQuestion } from 'react-icons/fa';
import { MdMovie } from 'react-icons/md';
import { GiChopsticks } from 'react-icons/gi';
import { AskLoginPrompt } from './LoginPrompts';

const messageDisplayStyles = {
  bg: "black",
  color: "white",
  p: 4,
  rounded: "lg",
  shadow: "sm",
  fontSize: "16px",
  textAlign: "left",
  className: "markdown-content"
};

// Loading Screen Component
const LoadingScreen = () => {
  return (
    <Box h="100vh" bg="black">
      {/* Header shimmer */}
      <Box 
        position="fixed"
        top={0}
        left={0}
        right={0}
        h="64px"
        className="shimmer-container"
      >
        <Box className="shimmer-effect" />
      </Box>

      {/* Main content area */}
      <Box 
        display="flex" 
        h="calc(100vh - 64px)" 
        w="100%" 
        pt="64px"
      >
        {/* Left column */}
        <Box 
          w="50%" 
          h="100%" 
          borderRight="1px" 
          borderColor="whiteAlpha.200"
          display="flex"
          flexDirection="column"
        >
          {/* Message area shimmer */}
          <Box 
            flex="1" 
            p={4}
            className="shimmer-container"
          >
            <Box className="shimmer-effect" />
          </Box>
          
          {/* Input area shimmer */}
          <Box p={4}>
            <Box 
              h="50px" 
              borderRadius="24px"
              className="shimmer-container"
            >
              <Box className="shimmer-effect" />
            </Box>
          </Box>
        </Box>

        {/* Divider */}
        <Box w="5px" h="100%" bg="whiteAlpha.200" />

        {/* Right column */}
        <Box 
          flex="1" 
          className="shimmer-container"
        >
          <Box className="shimmer-effect" />
        </Box>
      </Box>
    </Box>
  );
};

// Chat Input Component
const ChatInput = ({ 
  value, 
  onChange, 
  onSubmit, 
  disabled,
  isStreaming,
  onStopClick,
  handleSubmit 
}) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)", {
    ssr: true,
    fallback: false
  });
  
  return (
    <InputGroup size="md" w="full" maxW={{ base: "full", md: "500px" }} position="relative">
      <AutoResizeTextarea
        placeholder="Type your message..."
        fontSize={{ base: "16px", md: "md", lg: "md" }}
        value={value}
        onChange={onChange}
        color="black"
        backgroundColor="white"
        border="none"
        _placeholder={{ color: "gray.500" }}
        paddingEnd="4.5rem"
        minH={{ base: "50px", md: "45px", lg: "45px" }}
        maxH={isMobile ? "60px" : "160px"}
        lineHeight="1.2"
        pt="14px"
        pb="14px"
        borderRadius="24px"
        boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSubmit();
          }
        }}
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#ffffff',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#E2E8F0',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#CBD5E0',
          },
        }}
      />
      <Button
        onClick={isStreaming ? onStopClick : handleSubmit}
        disabled={!value.trim()}
        aria-label={isStreaming ? "Stop generating" : "Send message"}
        position="absolute"
        right="10px"
        top="50%" // Changed from bottom to top
        transform="translateY(-50%)" // Added to center vertically
        height="30px"
        width="44px"
        backgroundColor={isStreaming ? "gray.600" : "#FF0050"}
        _hover={{ bg: isStreaming ? "gray.700" : "#FF3371" }}
        color="white"
        borderRadius="full"
        size="sm"
        zIndex={2}
        padding={0}
      >
        {isStreaming ? (
          <Image src="/assets/ask_stop_icon.svg" alt="Stop" boxSize="16px"/>
        ) : (
          <Image 
            src="/assets/ask_send_icon.svg" 
            alt="Send" 
            boxSize={{ base: "22px", md: "18px" }}
          />
        )}
      </Button>
    </InputGroup>
  );
};


// Main Chat Interface Component
const ChatInterface = () => {
  const { authState } = useAuth(); // Use the global AuthContext
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [leftColumnWidth, setLeftColumnWidth] = useState(50);
  const [isMobile] = useMediaQuery("(max-width: 768px)", {
    ssr: true,
    fallback: false
  });
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const dividerRef = useRef(null);
  const chatContainerRef = useRef(null);
  const fetchController = useRef(new AbortController());
  const chatMessagesRef = useRef(null);
  const navigate = useNavigate();


  const handleMouseMove = useCallback((e) => {
    if (chatContainerRef.current) {
      const containerRect = chatContainerRef.current.getBoundingClientRect();
      const newWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;
      setLeftColumnWidth(Math.min(Math.max(newWidth, 30), 70));
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove, handleMouseUp]);

  const handleInputChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const handleStopClick = useCallback(() => {
    fetchController.current.abort();
    setIsStreaming(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!message.trim() || isStreaming) return;

    setIsStreaming(true);
    setResponse('');
    
    fetchController.current.abort();
    fetchController.current = new AbortController();
    const { signal } = fetchController.current;

    try {
      const userId = Cookies.get('userId');
      
      if (!userId) {
        throw new Error("User ID not found");
      }

      const res = await fetch(`/api/ask`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, query: message }),
        signal,
      });

      if (!res.ok || !res.body) {
        const errorText = await res.text();
        console.error("API Error:", res.status, res.statusText, errorText);
        throw new Error("Network response was not ok");
      }

      let fullResponse = "";
      const decoder = new TextDecoder();

      res.body.pipeTo(
        new WritableStream({
          write(chunk) {
            const chunkContent = decoder.decode(chunk);
            fullResponse += chunkContent;
            setResponse(prevResponse => prevResponse + chunkContent);
          },
          close() {
            setIsStreaming(false);
            setMessage('');
          },
          abort(err) {
            setIsStreaming(false);
            console.error("Stream aborted:", err);
          },
        })
      );
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        setResponse("Failed to fetch response. Please try again.");
      }
      setIsStreaming(false);
    }
  }, [message, isStreaming]);


  useEffect(() => {
    if (!isMobile) {
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isMobile, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatMessagesRef.current) {
        chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      }
    };
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, [response]);

  useEffect(() => {
    return () => {
      if (fetchController.current) {
        fetchController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLayoutReady(true);
    }, 10);
    
    return () => clearTimeout(timeoutId);
  }, []);

  // Render loading state while maintaining layout
  if (!isLayoutReady || authState.isLoading) {
    return <LoadingScreen />;
  }

  // Only show login prompt after loading is complete and we're sure user is not authenticated
  // if (!authState.isLoading && !authState.userId) {
  //   return (
  //     <Box h="100vh" bg="black">
  //       <Header />
  //       <Flex 
  //         h="calc(100vh - 64px)" 
  //         w="full" 
  //         pt="64px"
  //         justify="center"
  //         align="center"
  //       >
  //         <VStack spacing={4}>
  //           <Text fontSize="xl" color="white">
  //             Sign in to ask questions
  //           </Text>
  //           <Button
  //             onClick={() => navigate('/login')}
  //             bg="white"
  //             color="black"
  //             size="lg"
  //             _hover={{
  //               bg: "gray.100"
  //             }}
  //           >
  //             Sign In
  //           </Button>
  //         </VStack>
  //       </Flex>
  //     </Box>
  //   );
  // }

  if (!authState.isLoading && !authState.userId) {
    return (
      <Box h="100vh" bg="black">
        <Header />
        <Flex h="calc(100vh - 64px)" pt="64px">
          <Box w="100%" h="full" borderRight="1px" borderColor="whiteAlpha.200">
            <AskLoginPrompt onSignInClick={() => navigate('/login')} />
          </Box>
        </Flex>
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Box h="100vh" bg="black">
        <Header />
        <Flex direction="column" h="full">
          <Box 
            ref={chatMessagesRef}
            flex="1" 
            w="full"
            p={4}
            pt="80px"
            pb="100px"
            bg="black"
          >
            {(response || isStreaming) && (
              <Box {...messageDisplayStyles}>
                {isStreaming && !response ? (
                  <Spinner 
                    thickness="4px"
                    speed="0.75s"
                    emptyColor="gray.600"
                    color="white.500"
                    size="md"
                  />
                ) : (
                  <Markdown>{response + (isStreaming ? " ⚪" : "")}</Markdown>
                )}
              </Box>
            )}
          </Box>
          
          <Box 
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            p={4}
            bg="#1A202C"
            borderTop="1px"
            borderColor="whiteAlpha.200"
          >
            <ChatInput
              value={message}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              disabled={isStreaming}
              isStreaming={isStreaming}
              onStopClick={handleStopClick}
              handleSubmit={handleSubmit}
            />
          </Box>
        </Flex>
      </Box>
    );
  }

  return (
    <Box h="100vh" bg="black">
      <Header />
      <Flex 
        h="calc(100vh - 64px)" 
        w="full" 
        ref={chatContainerRef}
        pt="64px"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box 
            w={`${leftColumnWidth}%`} 
            h="full" 
            borderRight="1px" 
            borderColor="whiteAlpha.200"
            display="flex"
            flexDirection="column"
            position="relative"
        >
          <Box 
              flex="1" 
              p="4" 
              ref={chatMessagesRef}
              overflowY="auto"
              pb={{ base: "100px", md: "120px" }}
              pl={{ base: "4", md: "200px" }}
              pt={{ base: "4", md: "48px" }}
            >
            {(response || isStreaming) ? (
              <Box {...messageDisplayStyles}>
                {isStreaming && !response ? (
                  <Spinner 
                    thickness="4px"
                    speed="0.75s"
                    emptyColor="gray.600"
                    color="white.500"
                    size="md"
                  />
                ) : (
                  <Markdown>{response + (isStreaming ? " ⚪" : "")}</Markdown>
                )}
              </Box>
            ) : (
              <Flex align="center" justify="center" h="full">
                <Text color="gray.500" fontSize="xl">Ask Anything</Text>
              </Flex>
            )}
          </Box>
  
          <Box 
            p="4" 
            display="flex" 
            justifyContent="center"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            pl={{ base: "4", md: "200px" }}
            pt={{ base: "4", md: "48px" }}
            style={{
              transform: 'translateY(0)',
              zIndex: 10
            }}
          >
            <ChatInput
              value={message}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              disabled={isStreaming}
              isStreaming={isStreaming}
              onStopClick={handleStopClick}
              handleSubmit={handleSubmit}
            />
          </Box>
        </Box>
  
        <Box
          ref={dividerRef}
          w="5px"
          h="full"
          bg="whiteAlpha.200"
          cursor="col-resize"
          onMouseDown={handleMouseDown}
          _hover={{ bg: 'whiteAlpha.400' }}
          transition="background 0.2s"
        />
  
        <Box 
          flex="1" 
          h="full"
          overflowY="auto"
        >
          <QANotesView />
        </Box>
      </Flex>
    </Box>
  );
};

export default ChatInterface;


// CSS for shimmer effect (add to your CSS file)
const shimmerStyles = `
.shimmer-container {
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`;